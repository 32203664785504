import GitHubIcon from '@mui/icons-material/GitHub';
import { Container, Typography } from '@mui/material';
import React from 'react';

import '../App.css';
import Footer from '../Component/footer/Footer';
import NavBar from '../Component/navbar/NavBar';
import logo from '../resources/LandingBack.jpg';
import About from './landing/About';
import Future from './landing/Future';

export default function Landing() {
  return (
    <div>
      <NavBar />
      <div
        style={{
          position: 'relative'
        }}
      >
        <div
          style={{
            backgroundImage: `url(${logo})`
          }}
          className='landingBackground'
        />
        <Container className='mainGitHubButtonContainer'>
          <Typography variant='h1'>Set sail to your video game journey.</Typography>
          <Typography variant='h3'>Creative Rift regroup all project and games I&apos;ve made. Take a look !</Typography>
          <div
            onClick={() => {
              window.open('https://github.com/Creative-Rift', '_blank');
            }}
            className='mainGitHubButton'
          >
            <Typography className='mainGitHubButtonTitle'>GitHub</Typography>
            <GitHubIcon
              style={{
                fontSize: '40px',
                marginLeft: '6%'
              }}
            />
          </div>
        </Container>
      </div>
      <About />
      <Future />
      <Footer />
    </div>
  );
}
