import { Avatar, Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function ListItemElement(props: {
  projectName: string;
  title: string;
  image: string;
  desc: string;
  learnEnabled?: boolean | undefined;
  dlEnabled?: boolean | undefined;
}) {
  const { projectName, title, image, desc, learnEnabled, dlEnabled } = props;
  const navigation = useNavigate();

  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '5vh' }}>
      <div style={{ marginRight: '3vw' }}>
        <Avatar alt={projectName + ' image'} src={image} sx={{ width: 200, height: 200 }} />
      </div>
      <div style={{ marginTop: '3vh' }}>
        <Typography variant='h2'>{title}</Typography>
        <Typography variant='body1'>{desc}</Typography>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '1vh' }}>
          {learnEnabled !== undefined && (
            <Button
              variant='contained'
              style={{ marginRight: '2vw' }}
              disabled={!learnEnabled}
              onClick={() => navigation('/' + projectName.toLowerCase().replace(' ', ''))}
            >
              Learn More
            </Button>
          )}
          {dlEnabled !== undefined && (
            <Button
              color='secondary'
              variant='contained'
              onClick={() => navigation('/' + projectName.toLowerCase().replace(' ', '') + '/download')}
              disabled={!dlEnabled}
            >
              Download
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
