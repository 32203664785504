import { Container, Typography } from '@mui/material';
import React from 'react';

import ListItemElement from '../Component/List/ListItemElement';
import Footer from '../Component/footer/Footer';
import NavBar from '../Component/navbar/NavBar';
import Moul from '../resources/bomb_icon.png';
import WaterShader from '../resources/water/water-shader.png';

export default function Projects() {
  return (
    <div>
      <NavBar />
      <Container
        style={{
          textAlign: 'center',
          paddingTop: '20px',
          paddingBottom: '40px'
        }}
      >
        <Typography variant={'h1'}>Here you will find all projects I’ve made</Typography>
        <Typography variant={'subtitle1'}>I’m curious about everything so I made research and try to remake what I want</Typography>
      </Container>
      <Container
        style={{
          textAlign: 'left',
          maxWidth: '40em',
          position: 'relative',
          zIndex: '1',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <ListItemElement
          projectName={'watershader'}
          title={'Water Shader - 2024'}
          image={WaterShader}
          desc='I tried to recreate a water shader with modern render technique.'
          learnEnabled={true}
        />
        <ListItemElement
          projectName={'swpacker'}
          title={'SWPacker - 2023'}
          image={Moul}
          desc='Epitech Hub student project. I wanted to create a software that pack an unpack datas in a single file. '
          learnEnabled={true}
        />
      </Container>
      <Footer />
    </div>
  );
}
