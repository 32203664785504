import { Container, Typography } from '@mui/material';
import React from 'react';

import '../../App.css';
import Logo from '../Logo';
import Column from './Column';

export default function BigFooter() {
  return (
    <Container
      maxWidth='lg'
      style={{
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <div>
        <div
          style={{
            display: 'flex',
            position: 'relative'
          }}
        >
          <Logo />
        </div>
        <Typography variant='h3'>Copyright © 2020 - 2024 Guillaume S.</Typography>
      </div>
      <div
        style={{
          paddingBottom: '3%',
          paddingLeft: '5%',
          gap: '5vw',
          display: 'inline-flex',
          flexDirection: 'row'
        }}
      >
        <Column
          name='Download'
          links={[
            {
              name: 'Engine',
              navigation: '/engine'
            },
            {
              name: 'Project',
              navigation: '/projects'
            },
            {
              name: 'Game',
              navigation: '/games'
            }
          ]}
        />
        <Column
          name='About'
          links={[
            {
              name: 'Features',
              navigation: '/'
            },
            {
              name: 'License',
              navigation: '/'
            }
          ]}
        />
        <div>
          <a className='titleLink' href={'mailto:contact@creative-rift.com'}>
            Contact Us
          </a>
        </div>
      </div>
    </Container>
  );
}
