import { Container, Typography } from '@mui/material';
import React from 'react';
import Dunkerque from '../../resources/Dunkerque.png';

export default function About() {
  return (
    <Container
      style={{
        marginTop: '3vh',
        marginBottom: '3vh'
      }}
    >
      <Typography variant='h2' id='about' style={{ marginBottom: '2vh' }}>
        About Me
      </Typography>
      <Typography variant='body1' style={{ marginBottom: '2vh' }}>
        Ahoy, sailor! I&apos;m CptnFish (Guillaume S.), and welcome aboard my ship, Creative Rift. Here, you&apos;ll find all my projects
        related to game development. Explore various sections. Starting with my main project: <a href={'/engine'}>ShipWreck Engine</a>.
        ShipWreck Engine is a game engine designed for creating both 2D and 3D games.
      </Typography>
      <Typography variant='body1' style={{ marginBottom: '2vh' }}>
        You’ll also find other <a href={'/projects'}>projects</a> I’ve created, all focused on game tools or graphics rendering techniques,
        aimed at uncovering the art of game building. Lastly, check out my <a href={'/games'}>Games</a> section, where you can download and
        enjoy games I&apos;ve made alone or with friends. I hope you’ll have as much fun playing as I had creating them!
      </Typography>
      <img
        src={Dunkerque}
        alt='logo'
        style={{
          width: '100%',
          borderRadius: '20px'
        }}
      />
    </Container>
  );
}
