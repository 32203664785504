import { Container, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import '../../App.css';
import Logo from '../Logo';
import NavDrawer from './NavDrawer';

function Tab(props: { name: string; navigation: string }) {
  const { name, navigation } = props;
  return (
    <div>
      <a href={navigation} className='titleLinkNoDeco'>
        {name}
      </a>
    </div>
  );
}

export default function NavBar() {
  const matches = useMediaQuery('(min-width:800px)');
  const { palette } = useTheme();

  if (matches)
    return (
      <div
        style={{
          background: palette.background.default
        }}
      >
        <Container
          maxWidth='lg'
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '50px'
          }}
        >
          <div
            style={{
              paddingTop: '0.5em',
              paddingBottom: '0.5em'
            }}
          >
            <Logo />
          </div>
          {/*<Tab name='About' navigation='/#about' />*/}
          <Tab name='Engine' navigation='/engine' />
          <Tab name='Projects' navigation='/projects' />
          <Tab name='Games' navigation='/games' />
        </Container>
      </div>
    );
  else
    return (
      <div
        style={{
          background: palette.background.default
        }}
      >
        <Container>
          <div
            style={{
              paddingTop: '0.5em',
              paddingBottom: '0.5em',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Logo />
            <NavDrawer />
          </div>
        </Container>
      </div>
    );
}
