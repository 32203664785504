import { createTheme } from '@mui/material';

export const darkTheme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: 'rgb(115,169,243)',
          backgroundImage: 'linear-gradient(180deg, rgba(35,70,118,1) 0%, rgba(23,46,77,1) 55%)'
        }
      }
    }
  },
  palette: {
    primary: {
      main: '#6A7FDB',
      light: '#8697da',
      dark: '#4c67d7',
      contrastText: '#FFF'
    },
    secondary: {
      main: '#172E4D',
      light: '#29518a',
      dark: '#12243b',
      contrastText: '#FFF'
    },
    text: {
      primary: '#FFF',
      secondary: 'rgba(255,255,255,0.6)',
      disabled: 'rgba(255,255,255,0.3)'
    },
    background: {
      paper: 'rgb(115,169,243)',
      default: '#102036'
    },
    action: {
      disabled: 'rgba(255,255,255,0.2)',
      disabledBackground: 'rgba(255,255,255,0.2)'
    }
  },
  typography: {
    h1: {
      fontSize: '60px',
      fontWeight: '700',
      fontFamily: "'Courgette', cursive",
      lineHeight: '1.5em'
    },
    h2: {
      fontSize: '30px',
      fontWeight: '400',
      textDecoration: 'none',
      fontFamily: "'Courgette', cursive"
    },
    h3: {
      fontSize: '18px',
      fontWeight: '400',
      marginTop: '1em'
    },
    h4: {
      fontSize: '16px',
      fontWeight: '400'
    },
    body1: {
      textAlign: 'justify'
    },
    button: {
      textTransform: 'none'
    }
  }
});
