import { Container, Typography } from '@mui/material';
import React from 'react';

export default function Future() {
  return (
    <Container
      style={{
        marginTop: '3vh',
        marginBottom: '3vh'
      }}
    >
      <Typography variant='h2' id='about' style={{ marginBottom: '2vh' }}>
        What&apos;s next?
      </Typography>
      <Typography variant='body1'>
        My current focus is on understanding shaders and modern techniques for rendering grass, clouds, water, and more. The goal is to
        create a vibrant, customizable world (though not an open world yet!). Check out my latest project, The{' '}
        <a href={'/projects/water-shader'}>Water Shader</a>, which marks the beginning of this world.
      </Typography>
    </Container>
  );
}
