import { Container, Typography } from '@mui/material';
import React from 'react';

import Footer from '../Component/footer/Footer';
import NavBar from '../Component/navbar/NavBar';

export default function Engine() {
  return (
    <div>
      <NavBar />
      <Container
        style={{
          textAlign: 'left',
          maxWidth: '40em',
          position: 'relative',
          zIndex: '1',
          paddingTop: '150px',
          paddingBottom: '360px'
        }}
      >
        <Typography variant='h1'>The ShipWreck is too weak to be used.</Typography>
        <Typography variant='h3'>We&apos;re working hardly to provide a fully functional ShipWreck Engine.</Typography>
      </Container>
      <Footer />
    </div>
  );
}
